import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IPartner } from '../model/partner.model';
import { catchError, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  private readonly apiUrl =  environment.apiUrl;
  private readonly partnerAPI: string = environment.apiUrl + 'v2/partners/';

  constructor(private http: HttpClient) {}

  getTermsOfService(id: number): Observable<any> {
    return this.http.get(this.apiUrl + 'Partners/' + id + '/TOS', { responseType: 'blob' });
  }

  getPartnerSelf(): Observable<IPartner> {
    return this.http.get<IPartner>(this.partnerAPI + 'Partners/Self?includeBrandingQuote=' + false + '&includeBrandingLogo=' + true + '&includeBrandingTermsOfService=' + true);
  }
}
