import { IPartner } from '../model/partner.model';

export class GetTermsOfService {
  static readonly type = '[Partner] Get Terms Of Service';
}

export class SetPartner {
  static readonly type = '[Partner] Set Partner';
   constructor(public partner: IPartner) {}
}


export class GetPartner {
  static readonly type = '[Partner] GetPartner';
}
